import { DateTime } from 'luxon';
import styles from './info-blocks.module.scss';
type InfoBlocksProps = {
    className?: string;
    children: React.ReactNode;
};

export const InfoBlocks = (props: InfoBlocksProps) => {
    return (
        <section>
            <div className="grid-container">
                <div className={styles['info-blocks']}>{props.children}</div>
            </div>
        </section>
    );
};

export interface BatteryBlockProps {
    className?: string;
    lastReading: number;
}

export const BatteryBlock = ({ className, lastReading }: BatteryBlockProps) => {
    const maxVolt = 3.2;
    const batteryPerc = Math.round((lastReading / maxVolt) * 100);

    return (
        <div className={styles['info-block']}>
            <div className={styles['inner']}>
                <div className={styles['title']}>Batterij</div>
                <div className={styles['status']}>
                    <svg
                        className={styles['-battery']}
                        width="800px"
                        height="800px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7.5 10V14M11.5 10V14M15.5 10V14M21 13V11M6.2 18H16.8C17.9201 18 18.4802 18 18.908 17.782C19.2843 17.5903 19.5903 17.2843 19.782 16.908C20 16.4802 20 15.9201 20 14.8V9.2C20 8.0799 20 7.51984 19.782 7.09202C19.5903 6.71569 19.2843 6.40973 18.908 6.21799C18.4802 6 17.9201 6 16.8 6H6.2C5.0799 6 4.51984 6 4.09202 6.21799C3.71569 6.40973 3.40973 6.71569 3.21799 7.09202C3 7.51984 3 8.07989 3 9.2V14.8C3 15.9201 3 16.4802 3.21799 16.908C3.40973 17.2843 3.71569 17.5903 4.09202 17.782C4.51984 18 5.07989 18 6.2 18Z"
                            stroke="#045eaa"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <div className={styles['info']}>{batteryPerc}%</div>
                </div>
            </div>
        </div>
    );
};

export interface MoistureBlockProps {
    className?: string;
    moisture: number;
}

export const MoistureBlock = ({ className, moisture }: MoistureBlockProps) => {
    return (
        <div className={styles['info-block']}>
            <div className={styles['inner']}>
                <div className={styles['title']}>Luchtvochtigheid</div>
                <div className={styles['status']}>
                    <svg
                        width="20"
                        height="28"
                        viewBox="0 0 48 64"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M24 50C25.0981 50 25.9965 50.9 25.9965 52C25.9965 53.1 25.0981 54 24 54C16.276 54 10.0244 47.7375 10.0244 39.8875C10.0244 38.9 10.9179 37.8875 12.0209 37.8875C13.119 37.8875 14.0175 38.9 14.0175 39.8875C14.0175 45.525 18.4846 50 24 50ZM45.9616 39.8875C45.9616 52.15 36.1288 62 24 62C11.8712 62 2.03839 52.15 2.03839 39.8875C2.03839 30.7375 14.8036 11.1788 20.868 2.4125C22.4028 0.198125 25.5972 0.198125 27.132 2.4125C33.1964 11.1788 45.9616 30.7375 45.9616 39.8875ZM40.1592 32.925C38.9489 29.8625 37.2518 26.45 35.3302 22.9625C31.5368 16.1 27.0072 9.26625 24 4.915C20.9928 9.26625 16.4632 16.1 12.6698 22.9625C10.7444 26.45 9.04739 29.8625 7.842 32.925C6.60167 36.0625 6.03141 38.45 6.03141 39.8875C6.03141 49.9375 14.0798 58 24 58C33.9202 58 41.9686 49.9375 41.9686 39.8875C41.9686 38.45 41.3946 36.0625 40.1592 32.925Z"
                            fill="#045EAA"
                        />
                    </svg>
                    <div className={styles['info']}>{moisture}%</div>
                </div>
            </div>
        </div>
    );
};

export interface TemperatureBlockProps {
    className?: string;
    temperature: number;
}

export const TemperatureBlock = ({ className, temperature }: TemperatureBlockProps) => {
    if (temperature == -100) {
        var temperatureHtml = '';
    } else {
        var temperatureHtml = Math.round(temperature) + 'º';
    }
    return (
        <div className={styles['info-block']}>
            <div className={styles['inner']}>
                <div className={styles['title']}>Temperatuur</div>
                <div className={styles['status']}>
                    <svg
                        width="17"
                        height="22"
                        viewBox="0 0 40 64"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_53_459)">
                            <path
                                d="M26 48C26 51.3125 23.3125 54 20 54C16.6875 54 14 51.3125 14 48C14 45.39 15.6713 43.1713 17.8875 42.3438L18 26C18 24.8907 18.8906 24 19.8875 24C20.8844 24 21.8875 24.8907 21.8875 26L21.8876 42.3376C24.325 43.1751 26 45.3875 26 48ZM32 37.425C34.4875 40.2375 36 43.9375 36 48C36 56.8438 28.8438 64 20.1125 64H19.8875C11.0913 63.9375 3.95 56.7025 4 47.925C4.01563 43.8938 5.53125 40.2225 7.8875 37.425V12C7.8875 5.37505 13.15 0.112549 19.775 0.112549C26.4 0.112549 32 5.37505 32 12V37.425ZM32 48C32 43.015 29.1413 40.2338 28.1125 38.9375V12C28.1125 7.5938 24.5188 4.00005 20.225 4.00005C15.9313 4.00005 12 7.5938 12 12V38.9375C10.8125 40.265 8.03125 43 8.1125 47.9375C7.96875 54.525 13.325 59.95 19.925 60H20.0031C26.6125 60 32 54.6125 32 48Z"
                                fill="#045EAA"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_53_459">
                                <rect width="40" height="64" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <div className={styles['info']}>{temperatureHtml}</div>
                </div>
            </div>
        </div>
    );
};

export interface TimeBlockProps {
    className?: string;
    lastReading: number;
}

export const TimeBlock = ({ className, lastReading }: TimeBlockProps) => {
    var timestampNow = new Date().getTime() / 1000;
    var formattedDate = '';
    if (timestampNow - 3600 * 24 < lastReading) {
        formattedDate = 'Vandaag';
    } else if (timestampNow - 3600 * 24 * 2 < lastReading) {
        formattedDate = 'Gister';
    } else if (timestampNow - 3600 * 24 * 2 < lastReading) {
        formattedDate = 'Gister';
    } else {
        const today = new Date();
        const day = DateTime.fromSeconds(lastReading).toFormat('dd', { locale: 'nl' });
        const month = DateTime.fromSeconds(lastReading).toFormat('MMMM', { locale: 'nl' });
        formattedDate = day + ' ' + month;
    }

    const hours = DateTime.fromSeconds(lastReading).toFormat('H', { locale: 'nl' });
    const minutes = DateTime.fromSeconds(lastReading).toFormat('mm', { locale: 'nl' });
    var formattedTime = hours + ':' + minutes;

    return (
        <div className={styles['info-block']}>
            <div className={styles['inner']}>
                <div className={styles['title']}>Laatste meetpunt</div>
                <div className={styles['status']}>
                    <svg
                        width="64"
                        height="65"
                        viewBox="0 0 64 65"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M34 25.5428L38.3375 19.0303C38.95 18.1178 40.1875 17.8678 41.1125 18.4803C42.025 19.0928 42.275 20.3303 41.6625 21.2553L33.6625 33.2553C33.175 33.9803 32.2625 34.3178 31.425 34.0553C30.575 33.8053 29.8875 33.0303 29.8875 32.1428V14.1428C29.8875 13.0428 30.9 12.1428 31.8875 12.1428C33.1 12.1428 34 13.0428 34 14.1428V25.5428ZM64 32.1428C64 49.8178 49.675 64.1428 32 64.1428C14.325 64.1428 0 49.8178 0 32.1428C0 14.4678 14.325 0.142822 32 0.142822C49.675 0.142822 64 14.4678 64 32.1428ZM32 4.14282C16.5375 4.14282 4 16.6803 4 32.1428C4 47.6053 16.5375 60.1428 32 60.1428C47.4625 60.1428 60 47.6053 60 32.1428C60 16.6803 47.4625 4.14282 32 4.14282Z"
                            fill="#045EAA"
                        />
                    </svg>
                    <div className={`${styles['info']} ${styles['-time']}`}>
                        {formattedDate} {formattedTime}
                    </div>
                </div>
            </div>
        </div>
    );
};
