import axios from 'axios';
import BASE_URL from './apiConfig';

const API_URL = `${BASE_URL}`;
const API_URL_AUTHENTICATE = `${BASE_URL}/user/authenticate`;
const API_URL_ACTIVATE = `${BASE_URL}/user/activate`;
const API_URL_RESET = `${BASE_URL}/user/passwordreset`;
const API_URL_FORGET = `${BASE_URL}/user/passwordforgetemail`;
const API_URL_SWITCH_ORGANISATION = `${BASE_URL}/user/switchorganisation`;

export const register = (
    hash: string,
    username: string,
    firstName: string,
    lastName: string,
    password: string,
) => {
    return axios
        .post(API_URL_ACTIVATE, {
            hash,
            username,
            firstName,
            lastName,
            password,
        })
        .then((response) => {
            if (response.data.results) {
                localStorage.setItem('user', response.data.results);
            }

            return response.data;
        })
        .catch((error) => {
            console.error('Error:', error);
            throw error;
        });
};

export const passwordforget = (email2: string) => {
    var email = btoa(email2);
    return axios
        .post(API_URL_FORGET, {
            email,
        })
        .then((response) => {
            console.log(response);
            return response.data;
        })
        .catch((error) => {
            console.error('Error:', error);
            throw error;
        });
};

export const adminSwitchOrganisation = (organisationId: number, token: string) => {
    return axios
        .post(API_URL_SWITCH_ORGANISATION, {
            organisationId,
            token,
        })
        .then((response) => {
            if (response.data.results) {
                console.log(response.data.results);
                return response.data.results;
            }

            return response.data;
        })
        .catch((error) => {
            console.error('Error:', error);
            throw error;
        });
};

export const passwordreset = (hash: string, password: string) => {
    return axios
        .post(API_URL_RESET, {
            hash,
            password,
        })
        .then((response) => {
            console.log(response);

            return response.data;
        })
        .catch((error) => {
            console.error('Error:', error);
            throw error;
        });
};

export const login = (username: string, password: string) => {
    return axios
        .post(API_URL_AUTHENTICATE, {
            username,
            password,
        })
        .then((response) => {
            if (response.data.results) {
                localStorage.setItem('user', response.data.results);
            }

            return response.data;
        })
        .catch((error) => {
            console.error('Error:', error);
            throw error;
        });
};

export const logout = () => {
    localStorage.removeItem('user');
};

export const getCurrentUser = () => {
    const userStr = localStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);

    return null;
};
