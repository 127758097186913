import axios from 'axios';
import classNames from 'classnames';
import { gsap, Power3 } from 'gsap';
import { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Loader } from '../../components/loader/loader';
import BASE_URL from '../../services/apiConfig';
import authHeader from '../../services/auth-header';
import { adminSwitchOrganisation } from '../../services/auth-service';
import decodeToken from '../../services/decode';
import { AccountPage } from '../account-page/account-page';
import styles from './navigation.module.scss';

interface Organisation {
    id: number;
    name: string;
    admin_user: number;
}

export interface NavigationProps {
    className?: string;
}

export const Navigation = ({ className }: NavigationProps) => {
    const [showAccountPage, setShowAccountPage] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [showOrganisationSwitch, setShowOrganisationSwitch] = useState(false);
    const [organisationsData, setOrganisationsData] = useState<Organisation[]>([]);
    const [selectedOrganisation, setSelectedOrganisation] = useState<number | null>(null);
    const [organizationName, setOrganizationName] = useState('');
    const [totalCellCount, setTotalCellCount] = useState(0);
    let navigate: NavigateFunction = useNavigate();
    useEffect(() => {
        const token = localStorage.getItem('user')!;
        const tokenData = decodeToken();
        if (tokenData.access.organisations && tokenData.access.organisations == 3) {
            setShowOrganisationSwitch(true);
            fetchOrganisations();
        }
        async function fetchData() {
            try {
                const response = await axios.get(`${BASE_URL}/user/info?token=${token}`, {
                    headers: authHeader(),
                });

                const { results } = response.data;

                const organization = results.organisation;

                if (organization) {
                    setOrganizationName(organization.name);
                }

                // Extract the cell count from the results
                const cellCount = results.cells;
                setTotalCellCount(cellCount);
            } catch (error) {
                console.error('Error fetching organization data:', error);
            }
        }

        async function fetchOrganisations() {
            try {
                const token = localStorage.getItem('user')!;
                const tokenData = decodeToken();

                const response = await axios.get(`${BASE_URL}/organisations`, {
                    headers: authHeader(),
                });
                setOrganisationsData(response.data.results);
                setSelectedOrganisation(tokenData.organisation_id);
            } catch (error) {
                console.error('Error fetching organization data:', error);
            }
        }

        fetchData();
    }, []);

    const changeOrganisation = (e: any) => {
        setSelectedOrganisation(e);
        setLoaderVisible(true);
        var token = localStorage.getItem('user')!;
        adminSwitchOrganisation(e, token).then(
            (res) => {
                //alert(res);
                localStorage.setItem('user', res);
                setLoaderVisible(false);
                //
                setTimeout(function () {
                    //navigate('/');
                    window.location.reload();
                }, 200);

                //alert('joe');
                //
                //window.location.reload();
                /*toast.success('Login successful', {
                    position: 'top-right',
                    autoClose: 3000,
                });*/
            },
            (error) => {
                //alert('joe2');
            },
        );
    };

    const handleToggleAccountPage = () => {
        const navigation = document.getElementById('navigation')!;
        const scrollContainer = document.getElementById('scrollContainer')!;
        const menuBox = document.getElementById('menu-box');
        //scrollContainer.classList.toggle('-headerOpen', !showAccountPage);
        if (!showAccountPage) {
            document.body.classList.add(styles['disable-scroll']);
            setShowAccountPage(!showAccountPage);
            gsap.to(navigation, {
                y: '0vh',
                top: 0,
                height: '100vh',
                duration: 0.8,
                ease: Power3.easeInOut,
            });
            gsap.to(menuBox, {
                marginTop: 0,
                ease: Power3.easeInOut,
            });
            gsap.set(scrollContainer, {
                overflow: 'hidden',
            });
            scrollContainer.scrollTo(0, 0);

            document.body.classList.add(styles['disable-scroll']);
        } else {
            document.body.classList.remove(styles['disable-scroll']);
            gsap.to(navigation, {
                y: 0,
                top: 0,
                height: '96px',
                duration: 0.8,
                ease: Power3.easeInOut,
                onComplete: function () {
                    setShowAccountPage(!showAccountPage);
                    document.body.classList.remove(styles['disable-scroll']);
                },
            });
            gsap.to(menuBox, {
                marginTop: -24,
                ease: Power3.easeInOut,
            });
            gsap.set(scrollContainer, {
                overflow: 'scroll',
            });
        }
    };

    const testClick = () => {
        //alert('navigation click');
    };

    const testClick2 = () => {
        // alert('menu center click');
    };

    var development = false;
    var hostname = window.location.hostname;
    if (hostname == 'localhost') {
        development = true;
    } else if (hostname == 'dev01.albird.nl') {
        development = true;
    }

    return (
        <>
            <nav
                className={classNames(styles.navigation, className)}
                id="navigation"
                onClick={testClick}
            >
                <div className={classNames(styles.header)}>
                    <div className="grid-container -flex-nowrap">
                        <a href="/" className={styles.logo}>
                            <svg
                                width="71"
                                height="74"
                                viewBox="0 0 71 74"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M37.9965 59.0715C51.1602 56.7847 60.1442 43.6389 57.7855 30.1151C55.4219 16.5661 42.4702 7.18893 29.3796 9.54954C16.0635 11.9507 7.25855 24.8382 9.62529 38.4619C11.9875 52.0599 24.7963 61.3646 37.9965 59.0715ZM67.0144 72.3055C60.8698 70.1483 54.7405 67.9447 48.5652 65.8775C47.5185 65.527 46.3838 65.5472 45.3421 65.9126C40.7948 67.5083 36.152 68.2228 31.3549 67.7953C12.6712 66.1291 -0.961929 49.1953 1.10086 30.2443C3.06172 12.2323 18.5164 -0.803666 36.0983 0.723126C60.6109 2.852 74.4578 30.5035 61.7591 52.0243C61.1115 53.1218 61.0231 53.9408 61.5963 55.1171C64.4528 60.9817 67.1979 66.9008 70.2294 73.3265C70.2484 73.3666 70.2073 73.4102 70.1649 73.3954C68.8237 72.9324 67.9177 72.6228 67.0144 72.3055Z"
                                    fill="#F5801F"
                                />
                                <path
                                    d="M36.5533 39.8901C31.5709 42.5526 25.3465 40.8144 22.6979 36.0202C20.0393 31.2086 21.901 25.0645 26.8541 22.3021C31.7716 19.5594 38.0826 21.2243 40.761 25.9712C43.4646 30.7626 41.5056 37.2437 36.5533 39.8901ZM53.8448 32.4667C51.7104 31.1469 50.1626 30.0764 48.5079 29.2084C46.4339 28.1203 45.002 26.6205 43.8912 24.502C40.9702 18.9299 35.121 16.4158 28.9958 17.7878C22.8525 19.1634 18.2936 24.3567 17.8872 30.44C17.4647 36.7825 21.4897 42.473 27.6262 44.2081C34.0008 46.0108 41.0537 43.0472 43.9765 37.0584C44.859 35.249 45.9495 34.3976 47.8459 34.0781C49.6724 33.7703 51.4519 33.1817 53.8313 32.5594C53.8742 32.5482 53.8827 32.4901 53.8448 32.4667Z"
                                    fill="white"
                                />
                                <path
                                    d="M35.2185 23.3276C32.5771 22.1293 30.029 22.7188 29.0914 24.745C28.1313 26.8194 29.2908 28.9726 32.0481 30.2375C34.8049 31.5029 37.2032 30.9624 38.1543 28.8615C39.0811 26.8145 37.8693 24.5295 35.2185 23.3276Z"
                                    fill="#D33832"
                                />
                            </svg>
                        </a>
                        {development ? (
                            <>
                                <div className={styles['environment']}>Dev</div>
                            </>
                        ) : (
                            <></>
                        )}
                        <div className={styles['location-nav']} onClick={testClick2}>
                            {showOrganisationSwitch ? (
                                <>
                                    <select
                                        value={selectedOrganisation ?? ''}
                                        onChange={(e) => changeOrganisation(e.target.value)}
                                        required
                                    >
                                        {organisationsData.map((organisation: Organisation) => (
                                            <option key={organisation.id} value={organisation.id}>
                                                {organisation.name}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            ) : (
                                <>
                                    <h3 className={styles['h3-location']}>
                                        {organizationName || ' '}
                                    </h3>
                                </>
                            )}

                            <p className={styles['p-location']}>{totalCellCount} koelingen</p>
                        </div>
                        <div className={styles.menu} onClick={handleToggleAccountPage}>
                            {showAccountPage ? (
                                <svg
                                    width="46"
                                    height="46"
                                    viewBox="0 0 35 40"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M5.12564 5.62563C5.47919 5.27208 6.16422 5.37857 6.65235 5.86669L29.6333 28.8477C30.1195 29.3338 30.2279 30.0208 29.8744 30.3744C29.5208 30.7279 28.8338 30.6194 28.3477 30.1333L5.3667 7.15234C4.87857 6.66421 4.77209 5.97918 5.12564 5.62563Z"
                                        fill="#fff"
                                    />
                                    <path
                                        d="M5.12564 30.3744C4.77209 30.0208 4.87858 29.3358 5.3667 28.8477L28.3477 5.86669C28.8338 5.38056 29.5208 5.27208 29.8744 5.62563C30.2279 5.97919 30.1195 6.6662 29.6333 7.15234L6.65235 30.1333C6.16423 30.6214 5.4792 30.7279 5.12564 30.3744Z"
                                        fill="#fff"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    width="46"
                                    height="46"
                                    viewBox="0 0 46 46"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#clip0_53_498)">
                                        <path
                                            d="M5.5 31C5.5 30.5 6.05969 30.0909 6.75 30.0909H39.25C39.9375 30.0909 40.5 30.5 40.5 31C40.5 31.5 39.9375 31.9091 39.25 31.9091H6.75C6.05969 31.9091 5.5 31.5 5.5 31Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M5.5 21C5.5 20.5 6.05969 20.0909 6.75 20.0909H39.25C39.9375 20.0909 40.5 20.5 40.5 21C40.5 21.5 39.9375 21.9091 39.25 21.9091H6.75C6.05969 21.9091 5.5 21.5 5.5 21Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M5.5 21C5.5 20.5 6.05969 20.0909 6.75 20.0909H39.25C39.9375 20.0909 40.5 20.5 40.5 21C40.5 21.5 39.9375 21.9091 39.25 21.9091H6.75C6.05969 21.9091 5.5 21.5 5.5 21Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M5.5 11C5.5 10.5 6.05969 10.0909 6.75 10.0909H39.25C39.9375 10.0909 40.5 10.5 40.5 11C40.5 11.5 39.9375 11.9091 39.25 11.9091H6.75C6.05969 11.9091 5.5 11.5 5.5 11Z"
                                            fill="white"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_53_498">
                                            <rect
                                                width="35"
                                                height="40"
                                                fill="white"
                                                transform="translate(5.5 3)"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            )}
                        </div>
                    </div>
                </div>
                <div className={` ${styles['account-page-overlay']}  `}>
                    <AccountPage organizationName={organizationName} />
                </div>
            </nav>

            <Loader visible={loaderVisible} />
        </>
    );
};
