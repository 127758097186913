import axios from 'axios';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import BASE_URL from '../../services/apiConfig';
import authHeader from '../../services/auth-header';
import decodeToken from '../../services/decode';
import { closeModal, ModalButton, openModal } from '../modals/modal';
import modalStyle from './modal.module.scss';
gsap.registerPlugin(CustomEase);

export interface ModalEditTaskProps {
    taskId: number;
    callBack: () => void;
}

export const ModalEditTask = ({ taskId, callBack }: ModalEditTaskProps) => {
    const token = localStorage.getItem('user')!;
    const tokenData = decodeToken();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [endTime, setEndTime] = useState<any>();
    const [subTasks, setSubTasks] = useState([]);
    const [lastEmpty, setLastEmpty] = useState(true);
    const [taskRepeats, setTaskRepeats] = useState(false);

    const [days, setDays] = useState([
        { name: 'Maandag', value: false },
        { name: 'Dinsdag', value: false },
        { name: 'Woensdag', value: false },
        { name: 'Donderdag', value: false },
        { name: 'Vrijdag', value: false },
        { name: 'Zaterdag', value: false },
        { name: 'Zondag', value: false },
    ]);

    const fetchTaskData = async () => {
        console.log('fetched');
        try {
            const response = await axios.get(`${BASE_URL}/task/${taskId}`, {
                headers: authHeader(),
            });

            var task = response.data.results;
            setTitle(task.title);
            setDescription(task.description);
            setEndTime(new Date(task.end_time * 1000));
            setSubTasks(task.subtasks);
        } catch (error) {
            console.log(error);
        }
    };

    const taskData2 = (task: any) => {};

    const buttonActionClose = () => {
        closeModal(callBack);
    };
    const buttonActionSubmit = async () => {
        const updatedData = {
            title: title,
            description: description,
            subTasks: subTasks,
            endTime: Math.round(new Date(endTime).getTime() / 1000),
            //taskForUser: taskForUser,
        };
        console.log(updatedData);
        try {
            const response = await axios.put(`${BASE_URL}/task/${taskId}`, updatedData, {
                headers: authHeader(),
            });
            closeModal(callBack);
        } catch (error) {
            console.log(error);
        }
    };
    const buttonActionDelete = async () => {
        try {
            const response = await axios.delete(`${BASE_URL}/task/${taskId}/`, {
                headers: authHeader(),
            });
            closeModal(callBack);
        } catch (error) {
            console.log(error);
        }
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setTitle(value);
    };
    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setDescription(value);
    };

    const handleChangeSubtask = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        var index: any = e.target.getAttribute('data-key');
        index = parseInt(index);
        var array: any = subTasks.slice();
        console.log(value, index);
        //console.log(array.length, index);
        if (array.length == index + 1) {
            if (value != '') {
                if (!lastEmpty) {
                    //setLastEmpty(true);
                }
                array.push({ name: '', value: '' });
            }
        }
        array[index].value = value;
        //setSubTasks(array);
    };
    const addSubTask = () => {
        var array: any = subTasks.slice();
        array.push({ name: '', value: '' });
        setSubTasks(array);
    };
    const handleRemoveSubTask = (i: any) => {
        var array = subTasks.slice();
        array.splice(i, 1);
        setSubTasks(array);
    };

    const handleTaskRepeats = () => {
        var n = taskRepeats;
        if (n) {
            n = false;
        } else {
            n = true;
        }
        setTaskRepeats(n);
    };

    const toggleActiveDays = (name: any) => {
        const index = days.findIndex((day) => day.name === name);
        let tempDays = days.slice();
        if (tempDays[index].value) {
            tempDays[index].value = false;
        } else {
            tempDays[index].value = true;
        }

        setDays(tempDays);
    };

    useEffect(() => {
        fetchTaskData();
        openModal();
    }, [taskId]);
    return (
        <>
            <div className={modalStyle['modal-overlay']}>
                <div
                    className={modalStyle['modal-background']}
                    id="modal-background"
                    onClick={() => closeModal(callBack)}
                ></div>

                <div className={modalStyle['modal-element']} id="modal-element">
                    <div className={modalStyle['modal-content']}>
                        <div className={modalStyle['modal-title']}>
                            <h3>Taak bewerken</h3>
                            <h5></h5>
                        </div>
                        <div className={modalStyle['modal-form']}>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">Titel</label>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={title}
                                    onChange={handleTitleChange}
                                />
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">Omschrijving</label>
                                <input
                                    type="text"
                                    name=""
                                    id=""
                                    value={description}
                                    onChange={handleDescriptionChange}
                                />
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">Deadline</label>
                                <DatePicker
                                    selected={endTime}
                                    dateFormat="dd-MM-yyyy HH:mm"
                                    showTimeSelect
                                    onChange={(date) => setEndTime(date)}
                                />
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">Subtaken</label>
                                <div className={modalStyle['subtasks']}>
                                    {subTasks.map((subTask: any, index) => {
                                        return (
                                            <div className={modalStyle['subtask']}>
                                                <div
                                                    className={modalStyle['subtask-remove']}
                                                    onClick={() => handleRemoveSubTask(index)}
                                                >
                                                    <svg
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M19.75 10C19.75 10.4125 19.4125 10.75 19 10.75H10.75V19C10.75 19.4146 10.4146 19.7505 10 19.7505C9.58544 19.7505 9.25 19.4125 9.25 19V10.75H1C0.585438 10.75 0.25 10.4148 0.25 10.0005C0.25 9.5875 0.585625 9.25 1 9.25H9.25V0.999997C9.25 0.585434 9.58544 0.250465 10 0.250465C10.4146 0.250465 10.75 0.585622 10.75 0.999997V9.25H19C19.4125 9.25 19.75 9.5875 19.75 10Z"
                                                            fill="#045EAA"
                                                        />
                                                    </svg>
                                                </div>

                                                <input
                                                    type="text"
                                                    name="name"
                                                    key={index}
                                                    data-key={index}
                                                    value={subTask.name}
                                                    id="name"
                                                    onChange={handleChangeSubtask}
                                                />
                                            </div>
                                        );
                                    })}
                                    <div className={modalStyle['subtask-add']} onClick={addSubTask}>
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M19.75 10C19.75 10.4125 19.4125 10.75 19 10.75H10.75V19C10.75 19.4146 10.4146 19.7505 10 19.7505C9.58544 19.7505 9.25 19.4125 9.25 19V10.75H1C0.585438 10.75 0.25 10.4148 0.25 10.0005C0.25 9.5875 0.585625 9.25 1 9.25H9.25V0.999997C9.25 0.585434 9.58544 0.250465 10 0.250465C10.4146 0.250465 10.75 0.585622 10.75 0.999997V9.25H19C19.4125 9.25 19.75 9.5875 19.75 10Z"
                                                fill="#045EAA"
                                            />
                                        </svg>
                                        Subtaak toevoegen
                                    </div>
                                </div>
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="repeats" className={modalStyle['inline']}>
                                    Taak herhaalt zich
                                </label>
                                <div className={modalStyle['checkbox-container']}>
                                    <div
                                        className={`${modalStyle['checkbox']} ${
                                            taskRepeats === true ? modalStyle['-checked'] : ''
                                        }`}
                                        onClick={handleTaskRepeats}
                                    ></div>
                                </div>
                            </div>

                            {taskRepeats && (
                                <>
                                    <div className={modalStyle['form-group']}>
                                        <label htmlFor="repeats" className={modalStyle['inline']}>
                                            Herhaal elke
                                        </label>

                                        <input
                                            type="text"
                                            value="1"
                                            className={modalStyle['inline']}
                                        />
                                        <select name="" id="" className={modalStyle['inline']}>
                                            <option value="dag">dag</option>
                                            <option value="week" selected>
                                                week
                                            </option>
                                            <option value="maand">maand</option>
                                        </select>
                                    </div>
                                    <div className={modalStyle['form-group']}>
                                        <label htmlFor="name" className={modalStyle['inline']}>
                                            Herhalen op
                                        </label>
                                        <div className={modalStyle['days']}>
                                            {days.map((day, index) => {
                                                return (
                                                    <div
                                                        className={`${modalStyle['day']} ${
                                                            day.value === true
                                                                ? modalStyle['-active']
                                                                : ''
                                                        }`}
                                                        onClick={() => toggleActiveDays(day.name)}
                                                        key={index}
                                                    >
                                                        {day.name[0]}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className={modalStyle['modal-buttons']}>
                        <ModalButton
                            callBack={buttonActionDelete}
                            style="red"
                            icon="remove"
                            text="Verwijderen"
                        />
                        <ModalButton
                            callBack={buttonActionSubmit}
                            style=""
                            icon="plus"
                            text="Opslaan"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
