import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Link, NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import logo from '../../assets/logo.svg';
import { passwordreset } from '../../services/auth-service';
import styles from '../login/login.module.scss';
import { NeedHelp } from '../need-help/need-help';
export interface PasswordforgetUserProps {
    className?: string;
}

export const PasswordresetUser = ({ className }: PasswordforgetUserProps) => {
    const { hash } = useParams()!;
    const [successful, setSuccessful] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [showForm, setShowForm] = useState<boolean>(true);
    const [showResponse, setShowResponse] = useState<boolean>(false);
    const [showErrorForm, setShowErrorForm] = useState<boolean>(false);
    let navigate: NavigateFunction = useNavigate();

    useEffect(() => {
        /* axios
            .get(`${BASE_URL}/user/passwordforget/checkhash?hash=${hash}`)
            .then((response) => {
                if (response.data.results && response.data.results.length > 0) {
                    setShowForm(true);
                } else {
                    setShowForm(false);
                    setShowErrorForm(true);
                    setMessage('Invalid hash.');
                }
            })
            .catch((error) => {
                console.error(error);
                setShowForm(false);
                setMessage('Error checking the hash.');
            });*/
    }, [hash]);

    const initialValues = {
        hash: hash,
        password: '',
        password2: '',
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(6, 'Het wachtwoord moet uit minstens 6 tekens bestaan')
            .max(100, 'Het wachtwoord mag maximaal 100 tekens bevatten')
            .required('Dit veld is vereist'),
        password2: Yup.string()
            .min(6, 'Het wachtwoord moet uit minstens 6 tekens bestaan')
            .max(100, 'Het wachtwoord mag maximaal 100 tekens bevatten')
            .required('Dit veld is vereist')
            .oneOf([Yup.ref('password')], 'Wachtwoorden komen niet overeen'),
    });

    const handleRegister = (
        values: any,
        { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
    ) => {
        const { hash, password, password2 } = values;
        console.log(hash);
        if (password == password2) {
            passwordreset(hash, password).then(
                (response) => {
                    setShowForm(false);
                    setShowResponse(true);
                },
                (error) => {
                    const resMessage =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setMessage(resMessage);
                    setSuccessful(false);
                    setSubmitting(false);
                },
            );
        } else {
            setSuccessful(false);
            setSubmitting(false);
        }
    };

    return (
        <section className={classNames(styles.root, className)}>
            <div className={styles.container}>
                <div className={styles.logo}>
                    <img
                        src={logo}
                        className={classNames(styles['App-logo'], styles['img-logo'])}
                        alt="logo"
                    />
                </div>
                <div className={styles.login}>
                    <h4>Wachtwoord resetten</h4>
                    {showForm && !showErrorForm ? (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleRegister}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <>
                                        <Field
                                            name="hash"
                                            type="hidden"
                                            className="form-control"
                                            placeholder=""
                                            value={hash}
                                        />
                                        <div className="form-group">
                                            <label htmlFor="password"></label>
                                            <Field
                                                name="password"
                                                type="password"
                                                className="form-control"
                                                placeholder="Nieuw wachtwoord"
                                            />
                                            <ErrorMessage
                                                name="password"
                                                component="div"
                                                className="alert alert-danger"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password2"></label>
                                            <Field
                                                name="password2"
                                                type="password"
                                                className="form-control"
                                                placeholder="Nieuw wachtwoord herhalen"
                                            />
                                            <ErrorMessage
                                                name="password2"
                                                component="div"
                                                className="alert alert-danger"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <button
                                                type="submit"
                                                className="btn login"
                                                disabled={isSubmitting}
                                            >
                                                <span>
                                                    {isSubmitting
                                                        ? 'Versturen...'
                                                        : 'Wachtwoord resetten'}
                                                </span>
                                            </button>
                                        </div>
                                        {message && (
                                            <div className="form-group">
                                                <div
                                                    className={
                                                        successful
                                                            ? 'alert alert-success'
                                                            : 'alert alert-danger'
                                                    }
                                                    role="alert"
                                                >
                                                    {message}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                </Form>
                            )}
                        </Formik>
                    ) : showErrorForm ? (
                        <div className="form-group">
                            <div className={classNames(styles['alert-danger'])}>
                                <p>Ongeldige activatie. Neem contact op met support</p>
                                <NeedHelp />
                            </div>
                        </div>
                    ) : showResponse ? (
                        <div className="form-group">
                            <div className={classNames(styles['alert-danger'])}>
                                <p>Je wachtwoord is succesvol veranderd, je kunt nu inloggen</p>
                                <Link to="/">Inloggen</Link>
                            </div>
                        </div>
                    ) : null}
                </div>
                {/* <div className={styles.register}>
                        <p className={styles.small}>wachtwoord vergeten?</p>
                        <ButtonSmall>account aanmaken</ButtonSmall>
                    </div> */}
            </div>
        </section>
    );
};
