import classNames from 'classnames';
import styles from './need-help.module.scss';

export interface NeedHelpProps {
    className?: string;
}

export const NeedHelp = ({ className }: NeedHelpProps) => {
    const openWhatsApp = () => {
        window.open('https://web.whatsapp.com/send?phone=31626987065', '_blank');
    };

    return (
        <div className={classNames(styles.root, className)}>
            <button className={styles.help} onClick={openWhatsApp}>
                <svg
                    width="15"
                    height="20"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M38.6375 4H25.3375C17.98 4 12 9.98 12 17.3375V20C12 21.1055 12.8945 22 14 22C15.1055 22 16 21.1125 16 20V17.3375C16 12.185 20.1875 8 25.3375 8H38.65C43.8 8 48 12.1975 48 17.3625C48 20.7375 46.1725 23.8625 43.23 25.5187L31.0625 32.3625C29.175 33.425 28 35.425 28 37.5875V42C28 43.1055 28.8945 44.0013 30 44.0013C31.1055 44.0013 32 43.1 32 42V37.5875C32 36.8668 32.3906 36.1987 33.0195 35.8438L45.192 28.9963C49.3875 26.6375 52 22.175 52 17.3625C52 9.9925 46.0125 4 38.6375 4ZM30 50C27.7912 50 26 51.79 26 53.9987C26 56.2075 27.7912 57.9987 30 57.9987C32.2088 57.9987 34 56.2075 34 53.9987C34 51.79 32.2125 50 30 50Z"
                        fill="#045EAA"
                    />
                </svg>
                <p className={styles.btn}>Hulp nodig</p>
            </button>
        </div>
    );
};
