import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import logo from '../../assets/logo.svg';
import styles from './login.module.scss';

import { toast } from 'react-toastify';

import { login } from '../../services/auth-service';
import { ButtonSmall } from '../button-small/button-small';

export interface LoginProps {
    className?: string;
}

export const Login = ({ className }: LoginProps) => {
    let navigate: NavigateFunction = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');

    const initialValues: {
        username: string;
        password: string;
    } = {
        username: '',
        password: '',
    };

    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Dit veld is verplicht!'),
        password: Yup.string().required('Dit veld is verplicht!'),
    });

    const handleLogin = (formValue: { username: string; password: string }) => {
        const { username, password } = formValue;

        setMessage('');

        setLoading(true);

        login(username, password).then(
            () => {
                navigate('/');
                window.location.reload();
                toast.success('Login successful', {
                    position: 'top-right',
                    autoClose: 3000,
                });
            },
            (error) => {
                const resMessage =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            },
        );
    };

    const testClick = () => {};

    return (
        <section className={classNames(styles.root, className)}>
            <div className={styles.logo}>
                <img
                    src={logo}
                    className={classNames(styles['App-logo'], styles['img-logo'])}
                    alt="logo"
                />
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleLogin}
            >
                <div className={styles.container}>
                    <div className={styles.login}>
                        <h4>Inloggen</h4>
                        <Form>
                            <div className="form-group">
                                <label htmlFor="username"></label>
                                <Field
                                    name="username"
                                    type="text"
                                    className="form-control"
                                    placeholder="E-mailadres"
                                    onClick={testClick}
                                />
                                <ErrorMessage
                                    name="username"
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="password"></label>
                                <Field
                                    name="password"
                                    type="password"
                                    className="form-control"
                                    placeholder="Wachtwoord"
                                />
                                <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>

                            <div className="form-group">
                                <button type="submit" className="btn login" disabled={loading}>
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>INLOGGEN</span>
                                </button>
                            </div>

                            {message && (
                                <div className="form-group">
                                    <div className="alert alert-danger" role="alert">
                                        {message}
                                    </div>
                                </div>
                            )}
                        </Form>
                    </div>
                    <div className={styles.register}>
                        <Link to="/password-forget" className={styles['link']}>
                            wachtwoord vergeten?
                        </Link>
                        <div style={{ display: 'none' }}>
                            <ButtonSmall>account aanmaken</ButtonSmall>
                        </div>
                    </div>
                </div>
            </Formik>
        </section>
    );
};
