import classNames from 'classnames';
import styles from './button.module.scss';

export interface ButtonProps {
    className?: string;
    onClick: () => void;
    children?: JSX.Element | string;
    svgContent?: React.ReactNode;
    backgroundColor?: string;
    fontColor?: string;
}

export const Button = ({
    className,
    onClick,
    children,
    svgContent,
    backgroundColor,
    fontColor,
}: ButtonProps) => {
    const buttonStyle: React.CSSProperties = {
        backgroundColor: backgroundColor || undefined,
        color: fontColor || undefined,
    };

    return (
        <div className={classNames(styles.root, className)} onClick={onClick}>
            <button
                className={classNames(styles['btn-icon'], styles['custom-button'])}
                style={buttonStyle}
            >
                {svgContent}
                <p className={styles['btn-p']}>{children}</p>
            </button>
        </div>
    );
};
