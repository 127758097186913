import { gsap, Power3 } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { useEffect } from 'react';

import { ModalButton } from '../modals/modal';
import popupStyle from './popup.module.scss';
gsap.registerPlugin(CustomEase);

export interface popupProps {
    className?: string;
}

export const openPopup = () => {
    const popupEl: Element = document.getElementById('popup-overlay')!;
    const popupBackgroundEl: Element = document.getElementById('popup-background')!;
    const popupElementEl: Element = document.getElementById('popup-element')!;

    var duration = 600;
    //gsap.set(popupEl, { opacity: 1, 'pointer-events': 'all' });
    gsap.to(popupBackgroundEl, {
        opacity: 1,
        duration: duration / 1000,
        ease: Power3.easeInOut,
    });
    gsap.to(popupElementEl, {
        scale: 1,
        opacity: 1,
        delay: 0,
        duration: duration / 1000,
        ease: Power3.easeInOut,
    });
};
export const closePopup = (callBack: any) => {
    const popupEl: Element = document.getElementById('popup-overlay')!;
    const popupBackgroundEl: Element = document.getElementById('popup-background')!;
    const popupElementEl: Element = document.getElementById('popup-element')!;

    var duration = 600;
    //gsap.set(popupEl, { opacity: 1, 'pointer-events': 'all' });
    gsap.to(popupBackgroundEl, {
        opacity: 0,
        duration: duration / 1000,
        ease: Power3.easeInOut,
    });
    gsap.to(popupElementEl, {
        scale: 0.95,
        opacity: 0,
        duration: duration / 1000,
        ease: Power3.easeInOut,
    });
    setTimeout(function () {
        callBack();
    }, duration);
};

export interface PopupProps {
    callBack: () => void;
}

export const Popup = ({ callBack }: PopupProps) => {
    const token = localStorage.getItem('user')!;
    const buttonActionClose = () => {
        closePopup(callBack);
    };
    const buttonActionSubmit = () => {
        closePopup(callBack);
    };
    const buttonActionDelete = () => {
        closePopup(callBack);
    };

    useEffect(() => {
        openPopup();
    });
    return (
        <>
            <div className={popupStyle['popup-overlay']}>
                <div
                    className={popupStyle['popup-background']}
                    id="popup-background"
                    onClick={() => closePopup(callBack)}
                ></div>

                <div className={popupStyle['popup-element']} id="popup-element">
                    <div className={popupStyle['popup-content']}>
                        <div className={popupStyle['popup-title']}>
                            <h3>popup Titel</h3>
                            <h5>Subtitel</h5>
                        </div>
                    </div>
                    <div className={popupStyle['popup-buttons']}>
                        <ModalButton
                            callBack={buttonActionClose}
                            style="light"
                            icon="close"
                            text="Annuleren"
                        />
                        <ModalButton
                            callBack={buttonActionSubmit}
                            style=""
                            icon="plus"
                            text="Opslaan"
                        />
                        <ModalButton
                            callBack={buttonActionDelete}
                            style="red"
                            icon="remove"
                            text="Verwijderen"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
