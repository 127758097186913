import axios from 'axios';
import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { gsap, Power3 } from 'gsap';
import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';
import { CardNotification } from '../../components/cards/card-notification';
import { HeaderTitle } from '../../components/header-title/header-title';
import {
    BatteryBlock,
    InfoBlocks,
    MoistureBlock,
    TemperatureBlock,
    TimeBlock,
} from '../../components/info-blocks/info-blocks';
import { ModalEditCell } from '../../components/modals/edit-cell';
import { ModalNotification } from '../../components/modals/notification';
import { NotificationsOverview } from '../../components/notifications-overview/notifications-overview';
import { TemperatureLineChart } from '../../components/temperature-Line-Chart/temperatureLineChart';
import BASE_URL from '../../services/apiConfig';
import authHeader from '../../services/auth-header';
import styles from './cell.module.scss';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
);

interface Cell {
    id: number;
    name: string;
    active: number;
    notifications_enabled: number;
    notification_active: number;
    last_reading_timestamp: number;
    last_reading_temperature: number;
    notification_default_temperature: number;
    notification_deviation_min: number;
    notification_deviation_max: number;
}
export interface MeasurepointProps {
    className?: string;
    slug?: string;
}

export const Cell = ({ className }: MeasurepointProps) => {
    var currentDate = new Date();
    var lastMonth = new Date();

    lastMonth.setMonth(lastMonth.getMonth() - 1);
    const [historyData, setHistoryData] = useState<any>(null);
    const { id } = useParams<{ id: string }>();
    const [cellData, setCellData] = useState<any>(null);
    const [measurepointData, setMeasurepointData] = useState<any>(null);
    const [graphData, setGraphData] = useState<any>(null)!;
    const [graphTab, setGraphTab] = useState<any>(30);
    const [notificationData, setNotificationData] = useState<any>(null)!;
    const [graphLoading, setGraphLoading] = useState<boolean>(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [startDate, setStartDate] = useState<any>(lastMonth);
    const [endDate, setEndDate] = useState<any>(currentDate);
    const [showModalEditCell, setShowModalEditCell] = useState(false);
    const [currentNotification, setCurrentNotification] = useState<any>();

    const [showModalNotification, setShowModalNotification] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);

    const openModal = (cell: Cell) => {
        setShowEditForm(true);

        setTimeout(function () {
            var notificationModal = document.getElementById('notification-modal');
            gsap.to(notificationModal, { y: 0, ease: Power3.easeOut });
            var modalBackground = document.getElementById('modal-background');
            gsap.to(modalBackground, { opacity: 1, ease: Power3.easeOut, duration: 1 });
            var scrollContainer = document.getElementById('scrollContainer');
            gsap.set(scrollContainer, { overflow: 'hidden' });
        }, 100);
    };

    const onCloseModal = () => {
        //if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setTimeout(function () {
            var notificationModal = document.getElementById('notification-modal');
            gsap.to(notificationModal, { y: '100%', ease: Power3.easeOut, duration: 0.2 });

            var modalBackground = document.getElementById('modal-background');
            gsap.to(modalBackground, { opacity: 0, ease: Power3.easeOut, duration: 0.4 });
            var scrollContainer = document.getElementById('scrollContainer');
            gsap.set(scrollContainer, { overflow: 'scroll' });
            setTimeout(function () {
                //setShowModal(false);
                setShowEditForm(false);
            }, 400);
        }, 100);
        // }
    };

    useEffect(() => {
        const startDate2 = Math.round(new Date(startDate).getTime() / 1000);
        const endDate2 = Math.round(new Date(endDate).getTime() / 1000 + 24 * 60 * 60 - 3);

        fetchHistoryData(startDate2, endDate2);
    }, [startDate, endDate]);

    function updateGraph(period: number) {
        const currentDate = new Date();
        const currentTime = currentDate.getTime() / 1000;
        const lastWeekDate = new Date(currentDate.getTime() / 1000 - period * 24 * 60 * 60);
        const lastWeekTime = lastWeekDate.getTime() / 1000;
        fetchHistoryData(lastWeekTime, currentTime);

        setGraphTab(period);
    }
    const fetchBasicData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/cell/${id}`, {
                headers: authHeader(),
            });
            const jsonData = response.data.results;
            setCellData(jsonData);
        } catch (error) {
            console.error('Error fetching measurepoint data:', error);
        }
    };

    useEffect(() => {
        fetchBasicData();

        fetchNotificationData();
    }, [id]);

    const fetchNotificationData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/cell/${id}/notifications`, {
                headers: authHeader(),
            });
            const jsonData = response.data.results;
            setNotificationData(jsonData);
        } catch (error) {
            console.error('Error fetching measurepoint data:', error);
        }
    };

    const fetchHistoryData = async (start: number, end: number) => {
        setGraphLoading(true);

        try {
            const response = await axios.get(`${BASE_URL}/cell/${id}/history/daily`, {
                headers: authHeader(),
                params: {
                    start: start,
                    end: end,
                },
            });
            const jsonData = response.data.results;
            setGraphData(jsonData);
            setGraphLoading(false);
        } catch (error) {
            console.error('Error fetching measurepoint data:', error);
        }
    };

    const callbackEditCell = async () => {
        fetchBasicData();
        setShowModalEditCell(false);
    };
    const openNotificationModal = (notification: any) => {
        setCurrentNotification(notification);
        setShowModalNotification(true);
    };

    const callbackNotification = async () => {
        await Promise.all([fetchNotificationData()]);
        setTimeout(function () {
            setShowModalNotification(false);
        }, 600);
    };
    return (
        <>
            {cellData && (
                <>
                    <HeaderTitle title={cellData.name} />
                    <div className={styles['action-box']}>
                        <div
                            className={styles['action-icon']}
                            onClick={() => setShowModalEditCell(true)}
                        >
                            <svg
                                width="14"
                                height="14"
                                viewBox="0 0 10 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.84778 3.44845L7.35363 0.939661C7.25215 0.845874 7.13505 0.798981 7.00234 0.798981C6.86963 0.798981 6.75254 0.845874 6.65105 0.939661L0.152225 7.44611C0.105386 7.50082 0.0683061 7.55357 0.0409836 7.60437C0.0136611 7.65517 0 7.71965 0 7.79781V10.2949C0 10.4512 0.046838 10.5743 0.140515 10.6642C0.234192 10.754 0.355191 10.799 0.503513 10.799H2.99766C3.07572 10.799 3.14208 10.7873 3.19672 10.7638C3.25137 10.7404 3.30211 10.7013 3.34895 10.6466L9.84778 4.15185C9.94926 4.05025 10 3.93302 10 3.80015C10 3.66729 9.94926 3.55006 9.84778 3.44845ZM2.79859 9.8025H1.00703V7.9971L7.00234 1.99476L8.79391 3.80015L2.79859 9.8025Z"
                                    fill="#055192"
                                />
                            </svg>
                            Bewerken
                        </div>
                    </div>

                    <InfoBlocks>
                        <TemperatureBlock temperature={cellData.last_reading_temperature} />
                        <MoistureBlock moisture={cellData.last_reading_moisture} />
                        {cellData.last_reading_timestamp && (
                            <TimeBlock lastReading={cellData.last_reading_timestamp} />
                        )}
                        {cellData.last_reading_battery && (
                            <BatteryBlock lastReading={cellData.last_reading_battery} />
                        )}
                    </InfoBlocks>
                </>
            )}
            {notificationData && (
                <NotificationsOverview title="Actieve Meldingen">
                    {notificationData.map((notification: any) => (
                        <CardNotification
                            notification={notification}
                            callBack={() => openNotificationModal(notification)}
                        />
                    ))}
                </NotificationsOverview>
            )}

            {cellData && (
                <section>
                    <div className="grid-container">
                        <div className={styles['chart-container']}>
                            <div className={styles['chart-dates']} style={{ display: 'none' }}>
                                <div className={styles['date-box']}>
                                    <span className={styles['date-text']}>Begin Datum:</span>
                                    <DatePicker
                                        selected={startDate}
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => setStartDate(date)}
                                    />
                                </div>
                                <div className={styles['date-box']}>
                                    <span className={styles['date-text']}>Eind Datum:</span>
                                    <DatePicker
                                        dateFormat="dd-MM-yyyy"
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                    />
                                </div>
                            </div>
                            <h4>Overzicht</h4>
                            <div className={styles['chart-container-inner']}>
                                <div className={styles['chart-tabs']}>
                                    <ul>
                                        <li
                                            className={graphTab === 7 ? styles['active'] : ''}
                                            onClick={(e) => updateGraph(7)}
                                        >
                                            7d
                                        </li>
                                        <li
                                            className={graphTab === 30 ? styles['active'] : ''}
                                            onClick={(e) => updateGraph(30)}
                                        >
                                            30d
                                        </li>
                                        <li
                                            className={graphTab === 90 ? styles['active'] : ''}
                                            onClick={(e) => updateGraph(90)}
                                        >
                                            3m
                                        </li>
                                        <li
                                            className={graphTab === 365 ? styles['active'] : ''}
                                            onClick={(e) => updateGraph(365)}
                                        >
                                            12m
                                        </li>
                                    </ul>
                                </div>
                                {graphData && cellData && (
                                    <>
                                        <TemperatureLineChart
                                            reversedGroupedData={graphData}
                                            minLine={cellData.notification_deviation_min}
                                            maxLine={cellData.notification_deviation_max}
                                        />
                                    </>
                                )}
                                {graphLoading && cellData && (
                                    <>
                                        <div
                                            id={styles['graph-loader']}
                                            className={graphLoading ? styles['active'] : ''}
                                        >
                                            <svg
                                                version="1.1"
                                                id="L9"
                                                xmlns="http://www.w3.org/2000/svg"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                enableBackground="new 0 0 0 0"
                                            >
                                                <path
                                                    fill="blue"
                                                    d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                                                >
                                                    <animateTransform
                                                        attributeName="transform"
                                                        attributeType="XML"
                                                        type="rotate"
                                                        dur="1s"
                                                        from="0 50 50"
                                                        to="360 50 50"
                                                        repeatCount="indefinite"
                                                    />
                                                </path>
                                            </svg>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            )}

            <section className={styles['data-rows']} style={{ display: 'none' }}>
                <div className="grid-container">
                    <ul>
                        {
                            //graphData &&
                            // graphData.length > 0 &&
                            // graphData.map((d: any) => <TemperatureGraphInline info={d} />)
                        }
                    </ul>
                </div>
                <div className="grid-container">
                    <div className={styles['export']}>Exporteren</div>
                </div>
            </section>
            {showModalEditCell && cellData && (
                <ModalEditCell cell={cellData} callBack={() => callbackEditCell()} />
            )}
            {showModalNotification && cellData && (
                <ModalNotification
                    notification={currentNotification}
                    callBack={() => callbackNotification()}
                />
            )}
        </>
    );
};
