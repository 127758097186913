import { useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import styles from './App.module.scss';

import { Menu } from './components/menu/menu';
import { Navigation } from './components/navigation/navigation';

import 'react-toastify/dist/ReactToastify.css';

import { Login } from './components/login/login';
import { PasswordforgetUser } from './components/passwordforget-user/passwordforget-user';
import { PasswordresetUser } from './components/passwordreset-user/passwordreset-user';
import { RegisterUser } from './components/register-user/register-user';
import { Cell } from './pages/cell/cell';
import { Cells } from './pages/cells/cells';
import { Notifications } from './pages/notifications/notifications';
import { Settings } from './pages/settings/settings';
import { Tasks } from './pages/tasks/tasks';

const App = () => {
    const [activeComponent, setActiveComponent] = useState('locations');
    const isLoggedIn = localStorage.getItem('user');
    const location = useLocation();

    return (
        <>
            <div className={styles.App} id="app">
                {isLoggedIn ? (
                    <>
                        <div className={styles.scrollContainer} id="scrollContainer">
                            <Navigation />
                            <Menu setActiveComponent={setActiveComponent} />
                            <div className={styles.main}>
                                <Routes>
                                    <Route path="/" element={<Cells />} />
                                    <Route path="/cell/:id" element={<Cell />} />
                                    <Route path="/notifications" element={<Notifications />} />
                                    <Route path="/tasks" element={<Tasks />} />
                                    <Route path="/settings" element={<Settings />} />
                                </Routes>
                            </div>
                        </div>
                    </>
                ) : location.pathname.startsWith('/activate/') ? (
                    <>
                        <div className={styles.scrollContainer} id="scrollContainer">
                            <Routes>
                                <Route path="/activate/:hash" element={<RegisterUser />} />
                            </Routes>
                        </div>
                    </>
                ) : location.pathname.startsWith('/password-forget') ? (
                    <>
                        <div className={styles.scrollContainer} id="scrollContainer">
                            <Routes>
                                <Route path="/password-forget" element={<PasswordforgetUser />} />
                            </Routes>
                        </div>
                    </>
                ) : location.pathname.startsWith('/password-reset/') ? (
                    <>
                        <div className={styles.scrollContainer} id="scrollContainer">
                            <Routes>
                                <Route
                                    path="/password-reset/:hash"
                                    element={<PasswordresetUser />}
                                />
                            </Routes>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.scrollContainer} id="scrollContainer">
                            <Login />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default App;
