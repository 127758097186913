import axios from 'axios';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { useEffect, useState } from 'react';
import BASE_URL from '../../services/apiConfig';
import authHeader from '../../services/auth-header';
import decodeToken from '../../services/decode';
import { closeModal, ModalButton, openModal } from '../modals/modal';
import modalStyle from './modal.module.scss';

gsap.registerPlugin(CustomEase);

export interface ModalEditCellProps {
    cell: any;
    callBack: () => void;
}
export interface Location {
    id: number;
    name: string;
}
export const ModalEditCell = ({ cell, callBack }: ModalEditCellProps) => {
    const token = localStorage.getItem('user')!;
    const tokenData = decodeToken();

    var notificationsState = false;
    if (cell.notifications_enabled == '1') {
        notificationsState = true;
    }

    const [locations, setLocations] = useState<Location[]>([]);
    const [cellName, setName] = useState('' + cell.name + '');
    const [locationId, setLocationId] = useState(cell.location_id);
    const [notificationsEnabledChecked, setNotificationsEnabledChecked] =
        useState(notificationsState);
    const [deviationMin, setDeviationMin] = useState('' + cell.notification_deviation_min + '');
    const [deviationMax, setDeviationMax] = useState('' + cell.notification_deviation_max + '');
    const [minimalTime, setMinimalTime] = useState(3600);

    const handleNotificationsEnabled = () => {
        var n = notificationsEnabledChecked;
        if (n) {
            n = false;
        } else {
            n = true;
        }
        setNotificationsEnabledChecked(n);
    };
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setName(value);
    };

    const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/[^-0-9]/g, '');
        setDeviationMin(value);
    };

    const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/[^-0-9]/g, '');
        setDeviationMax(value);
    };

    const handleLocationChange = (e: any) => {
        setLocationId(e);
    };

    useEffect(() => {
        const getLocations = async () => {
            const token = localStorage.getItem('user')!;
            const tokenData = decodeToken();
            try {
                const response = await axios.get(
                    `${BASE_URL}/organisation/` + tokenData.organisation_id + `/locations`,
                    {
                        headers: authHeader(),
                    },
                );
                console.log(response.data.results);
                setLocations(response.data.results);
            } catch (error) {
                console.error('Error updating cell data:', error);
            }
        };

        getLocations();
    }, []);

    const buttonActionSubmit = async () => {
        var notificationsEnabled = 0;
        if (notificationsEnabledChecked) {
            notificationsEnabled = 1;
        }

        try {
            const updatedData = {
                cellName,
                locationId,
                notificationsEnabled,
                deviationMin,
                deviationMax,
                minimalTime,
            };

            await axios.put(`${BASE_URL}/cell/${cell.id}`, updatedData, {
                headers: authHeader(),
            });

            closeModal(callBack);
        } catch (error) {
            console.error('Error updating cell data:', error);
        }
    };
    const buttonActionDelete = () => {
        closeModal(callBack);
    };

    useEffect(() => {
        openModal();
    });

    return (
        <>
            <div className={modalStyle['modal-overlay']}>
                <div
                    className={modalStyle['modal-background']}
                    id="modal-background"
                    onClick={() => closeModal(callBack)}
                ></div>

                <div className={modalStyle['modal-element']} id="modal-element">
                    <div className={modalStyle['modal-content']}>
                        <div className={modalStyle['modal-title']}>
                            <h3>Koeling bewerken</h3>
                            <h5></h5>
                        </div>
                        <div className={modalStyle['modal-form']}>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">Naam</label>
                                <input type="text" value={cellName} onChange={handleNameChange} />
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">Geplaatst bij</label>
                                <select
                                    name="location"
                                    onChange={(e) => handleLocationChange(e.target.value)}
                                    defaultValue={locationId}
                                >
                                    {locations.map((location: Location) => (
                                        <option key={location.id} value={location.id}>
                                            {location.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className={modalStyle['form-group']}>
                                <label htmlFor="repeats">Minimale Temperatuur</label>
                                <input
                                    type="text"
                                    placeholder={`Minimale temperatuur: (${cell.notification_deviation_min})`}
                                    value={deviationMin}
                                    onChange={handleMinChange}
                                />
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="repeats">Maximale Temperatuur</label>
                                <input
                                    type="text"
                                    placeholder={`Maximale temperatuur: (${cell.notification_deviation_max})`}
                                    value={deviationMax}
                                    onChange={handleMaxChange}
                                />
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="repeats" className={modalStyle['inline']}>
                                    Notificaties
                                </label>
                                <div className={modalStyle['checkbox-container']}>
                                    <div
                                        className={`${modalStyle['checkbox']} ${
                                            notificationsEnabledChecked === true
                                                ? modalStyle['-checked']
                                                : ''
                                        }`}
                                        onClick={handleNotificationsEnabled}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={modalStyle['modal-buttons']}>
                        {/*<ModalButton
                            callBack={buttonActionClose}
                            style="light"
                            icon="close"
                            text="Annuleren"
                                        /> */}
                        <ModalButton
                            callBack={buttonActionSubmit}
                            style=""
                            icon="check"
                            text="Opslaan"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
