import axios from 'axios';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { useEffect, useState } from 'react';
import BASE_URL from '../../services/apiConfig';
import authHeader from '../../services/auth-header';
import decodeToken from '../../services/decode';
import { closeModal, ModalButton, openModal } from '../modals/modal';
import modalStyle from './modal.module.scss';
gsap.registerPlugin(CustomEase);

export interface ModalProps {
    callBack: () => void;
}

export const ModalAddLocation = ({ callBack }: ModalProps) => {
    const [scannedData, setScannedData] = useState<string | null>(null);
    const [qrCodeScannerActive, setQrCodeScannerActive] = useState(false);
    const token = localStorage.getItem('user')!;
    const tokenData = decodeToken();
    const buttonActionClose = () => {
        closeModal(callBack);
    };
    const buttonActionSubmit = async () => {
        try {
            const response = await axios.get(
                `${BASE_URL}/organisation/` + tokenData.organisation_id + `/locations`,
                {
                    headers: authHeader(),
                },
            );
            console.log(response.data.results);
            closeModal(callBack);
        } catch (error) {}
    };
    const buttonActionDelete = () => {
        closeModal(callBack);
    };
    useEffect(() => {
        let scanner: Html5QrcodeScanner;

        const startScanner = async () => {
            setQrCodeScannerActive(true);
            try {
                scanner = new Html5QrcodeScanner(
                    'reader',
                    {
                        fps: 24,
                        qrbox: { width: 250, height: 300 },
                    },
                    /* verbose= */ false,
                );

                scanner.render(
                    (decodedText: string, decodedResult: any) => {
                        setScannedData(decodedText);
                        scanner.clear();
                    },
                    (error: any) => {
                        // console.warn('Code scan error:', error);
                    },
                );
            } catch (err) {
                //console.error('Failed to start scanner:', err);
            }
        };
        if (!qrCodeScannerActive) {
            startScanner();
        }

        return () => {
            if (scanner) {
                console.log('joaaa');
                scanner.clear();
            }
        };
    }, []);
    useEffect(() => {
        openModal();
    });
    return (
        <>
            <div className={modalStyle['modal-overlay']}>
                <div
                    className={modalStyle['modal-background']}
                    id="modal-background"
                    onClick={() => closeModal(callBack)}
                ></div>

                <div className={modalStyle['modal-element']} id="modal-element">
                    <div className={modalStyle['modal-content']}>
                        <div className={modalStyle['modal-title']}>
                            <h3>Locatie Toevoegen</h3>
                            <h5></h5>
                        </div>
                    </div>
                    <div className={modalStyle['modal-buttons']}>
                        <ModalButton
                            callBack={buttonActionClose}
                            style="light"
                            icon="close"
                            text="Annuleren"
                        />
                        <ModalButton
                            callBack={buttonActionSubmit}
                            style=""
                            icon="plus"
                            text="Opslaan"
                        />
                        {/*<ModalButton
                            callBack={buttonActionDelete}
                            style="red"
                            icon="remove"
                            text="Verwijderen"
    />*/}
                    </div>
                </div>
            </div>
        </>
    );
};
