import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Link, NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import logo from '../../assets/logo.svg';
import { passwordforget } from '../../services/auth-service';
import styles from '../login/login.module.scss';
import { NeedHelp } from '../need-help/need-help';

export interface PasswordforgetUserProps {
    className?: string;
}

export const PasswordforgetUser = ({ className }: PasswordforgetUserProps) => {
    const { hash } = useParams();
    const [successful, setSuccessful] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [showForm, setShowForm] = useState<boolean>(true);
    const [showResponse, setShowResponse] = useState<boolean>(false);
    const [showErrorForm, setShowErrorForm] = useState<boolean>(false);
    let navigate: NavigateFunction = useNavigate();

    useEffect(() => {
        /* axios
            .get(`${BASE_URL}/user/passwordforget/checkhash?hash=${hash}`)
            .then((response) => {
                if (response.data.results && response.data.results.length > 0) {
                    setShowForm(true);
                } else {
                    setShowForm(false);
                    setShowErrorForm(true);
                    setMessage('Invalid hash.');
                }
            })
            .catch((error) => {
                console.error(error);
                setShowForm(false);
                setMessage('Error checking the hash.');
            });*/
    }, [hash]);

    const initialValues = {
        email: '',
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .min(3, 'De gebruikersnaam moet uit minstens 3 tekens bestaan')
            .max(100, 'De gebruikersnaam mag maximaal 20 tekens bevatten')
            .required('Email is vereist'),
    });

    const handleRegister = (
        values: any,
        { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
    ) => {
        const { email } = values;

        passwordforget(email).then(
            (response) => {
                console.log(response);
                setShowForm(false);
                setShowResponse(true);
            },
            (error) => {
                const resMessage =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();

                setMessage(resMessage);
                setSuccessful(false);
                setSubmitting(false);
            },
        );
    };

    return (
        <section className={classNames(styles.root, className)}>
            <div className={styles.container}>
                <div className={styles.logo}>
                    <img
                        src={logo}
                        className={classNames(styles['App-logo'], styles['img-logo'])}
                        alt="logo"
                    />
                </div>
                <div className={styles.login}>
                    <h4>Wachtwoord vergeten</h4>
                    {showForm && !showErrorForm ? (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleRegister}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="email"></label>
                                            <Field
                                                name="email"
                                                type="text"
                                                className="form-control"
                                                placeholder="E-mail"
                                            />
                                            <ErrorMessage
                                                name="email"
                                                component="div"
                                                className="alert alert-danger"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <button
                                                type="submit"
                                                className="btn login"
                                                disabled={isSubmitting}
                                            >
                                                <span>
                                                    {isSubmitting
                                                        ? 'Submitting...'
                                                        : 'Email versturen'}
                                                </span>
                                            </button>
                                        </div>
                                        {message && (
                                            <div className="form-group">
                                                <div
                                                    className={
                                                        successful
                                                            ? 'alert alert-success'
                                                            : 'alert alert-danger'
                                                    }
                                                    role="alert"
                                                >
                                                    {message}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                </Form>
                            )}
                        </Formik>
                    ) : showErrorForm ? (
                        <div className="form-group">
                            <div className={classNames(styles['alert-danger'])}>
                                <p>Ongeldige activatie. Neem contact op met support</p>
                                <NeedHelp />
                            </div>
                        </div>
                    ) : showResponse ? (
                        <div className="form-group">
                            <div className={classNames(styles['alert-danger'])}>
                                <p>
                                    Indien het e-mailadres bestaat is er een link om een nieuw
                                    wachtwoord in te stellen verstuurd.
                                </p>
                                <Link to="/" className={styles['back-button']}>
                                    Terug naar home
                                </Link>
                            </div>
                        </div>
                    ) : null}
                </div>
                {/* <div className={styles.register}>
                        <p className={styles.small}>wachtwoord vergeten?</p>
                        <ButtonSmall>account aanmaken</ButtonSmall>
                    </div> */}
            </div>
        </section>
    );
};
