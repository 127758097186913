import { gsap, Power3 } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { useEffect } from 'react';
import styles from './loader.module.scss';
gsap.registerPlugin(CustomEase);

export interface LoaderProps {
    visible?: boolean;
}

export const Loader = ({ visible }: LoaderProps) => {
    //const [loaderState, setLoaderState] = useState(visible);

    useEffect(() => {
        if (visible) {
            showLoader();
        } else {
            hideLoader();
        }
    }, [visible]);

    const showLoader = () => {
        var duration = 400;
        const loaderEl: Element = document.getElementById('loader')!;
        const loaderBgEl: Element = document.getElementById('loader-bg')!;
        const loaderBoxEl: Element = document.getElementById('loader-box')!;
        gsap.set(loaderEl, { opacity: 1, 'pointer-events': 'all' });
        //gsap.to(modalBoxEl, { y: '0%', duration: duration / 1000, ease: Power3.easeInOut });
        gsap.to(loaderBgEl, {
            opacity: 1,
            duration: duration / 1000,
            ease: Power3.easeInOut,
        });
        gsap.to(loaderBoxEl, {
            opacity: 1,
            duration: duration / 1000,
            ease: Power3.easeInOut,
        });
    };

    const hideLoader = () => {
        var duration = 400;
        const loaderEl: Element = document.getElementById('loader')!;
        const loaderBgEl: Element = document.getElementById('loader-bg')!;
        const loaderBoxEl: Element = document.getElementById('loader-box')!;
        gsap.to(loaderBgEl, {
            opacity: 0,
            duration: duration / 1000,
            ease: Power3.easeInOut,
        });
        gsap.to(loaderBoxEl, {
            opacity: 0,
            duration: duration / 1000,
            ease: Power3.easeInOut,
        });

        setTimeout(function () {
            gsap.set(loaderEl, { opacity: 0, 'pointer-events': 'none' });
        }, duration);
    };

    return (
        <div className={styles['loader']} id="loader">
            <div className={styles['bg']} id="loader-bg"></div>
            <div className={styles['box']} id="loader-box">
                <svg
                    version="1.1"
                    id="L9"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 100 100"
                    enableBackground="new 0 0 0 0"
                >
                    <path
                        fill="blue"
                        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                    >
                        <animateTransform
                            attributeName="transform"
                            attributeType="XML"
                            type="rotate"
                            dur="1s"
                            from="0 50 50"
                            to="360 50 50"
                            repeatCount="indefinite"
                        />
                    </path>
                </svg>
            </div>
        </div>
    );
};
