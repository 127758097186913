import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './cards.module.scss';
gsap.registerPlugin(CustomEase);

export interface CardCellNotificationProps {
    notification: any;
    callBack: () => void;
}

export const CardNotification = ({ notification, callBack }: CardCellNotificationProps) => {
    const [name, setName] = useState('');
    const [link, setLink] = useState('#');
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (notification.hasOwnProperty('cell')) {
            setName(notification.cell.name);

            setLink(`/cell/${notification.cell_id}`);
        }
        if (notification.active == '1') {
            setActive(true);
        }
    }, []);

    var last_temp = notification.temp;
    if (last_temp) {
        last_temp = Math.round(last_temp) + '°';
    } else {
        last_temp = '-';
    }
    var lastReading = notification.last_reading_timestamp;
    var last_reading_timestamp_text = 'Geen data';
    if (lastReading) {
        var timestampNow = Math.round(new Date().getTime() / 1000);

        const day = DateTime.fromSeconds(lastReading).toFormat('dd', { locale: 'nl' });
        const month = DateTime.fromSeconds(lastReading).toFormat('MMMM', { locale: 'nl' });
        var formattedDate = day + ' ' + month;
        const hours = DateTime.fromSeconds(lastReading).toFormat('H', { locale: 'nl' });
        const minutes = DateTime.fromSeconds(lastReading).toFormat('mm', { locale: 'nl' });
        var formattedTime = hours + ':' + minutes;

        last_reading_timestamp_text = 'Laatste meting: ' + formattedDate + ' om ' + formattedTime;
    }

    var description: string = '';
    if (notification.deviation_temp >= 0) {
        description = 'De koeling is ' + notification.deviation_temp.toFixed(1) + ' graden te warm';
    } else if (notification.deviation_temp < 0) {
        description = 'De koeling is ' + notification.deviation_temp.toFixed(1) + ' graden te koel';
    }
    var description2: string = '';
    if (notification.time_active) {
        var t = notification.time_active;
        var n: any = '';
        if (t < 60) {
            n = t;
            description2 = ' voor een periode van ' + n + ' seconden ';
        } else if (t < 60 * 60) {
            n = Math.round(t / 60);
            description2 = ' voor een periode van ' + n + ' minuten';
        } else if (t < 60 * 60 * 24) {
            n = Math.round(t / (60 * 60));
            description2 = ' voor een periode van ' + n + ' uur ';
        } else {
            n = Math.round(t / (60 * 60 * 24));
            description2 = ' voor een periode van ' + n + ' dagen ';
        }
    }

    return (
        <li
            className={styles['card-item']}
            data-location-id={notification.location_id}
            onClick={(e) => callBack()}
        >
            <Link to="" className={styles['card-link']}>
                <div className={styles['card-inner']}>
                    <div className={styles['temperature-box']}>
                        <svg
                            width="17"
                            height="22"
                            viewBox="0 0 40 64"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M26 48C26 51.3125 23.3125 54 20 54C16.6875 54 14 51.3125 14 48C14 45.39 15.6713 43.1713 17.8875 42.3438L18 26C18 24.8907 18.8906 24 19.8875 24C20.8844 24 21.8875 24.8907 21.8875 26L21.8876 42.3376C24.325 43.1751 26 45.3875 26 48ZM32 37.425C34.4875 40.2375 36 43.9375 36 48C36 56.8438 28.8438 64 20.1125 64H19.8875C11.0913 63.9375 3.95 56.7025 4 47.925C4.01563 43.8938 5.53125 40.2225 7.8875 37.425V12C7.8875 5.37505 13.15 0.112549 19.775 0.112549C26.4 0.112549 32 5.37505 32 12V37.425ZM32 48C32 43.015 29.1413 40.2338 28.1125 38.9375V12C28.1125 7.5938 24.5188 4.00005 20.225 4.00005C15.9313 4.00005 12 7.5938 12 12V38.9375C10.8125 40.265 8.03125 43 8.1125 47.9375C7.96875 54.525 13.325 59.95 19.925 60H20.0031C26.6125 60 32 54.6125 32 48Z"
                                fill="#045EAA"
                            />
                        </svg>

                        <div className={styles['digits']}>{last_temp}</div>
                    </div>
                    <div className={styles['content-box']}>
                        <p className={styles['title']}>{name}</p>
                        <p className={styles['info']}>{description + description2}</p>
                    </div>
                    {active && (
                        <div className={styles['warning-box']}>
                            <svg
                                width="24"
                                height="22"
                                viewBox="0 0 24 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M23.732 18.5469L13.7336 1.48438C12.9681 0.171875 11.0364 0.171875 10.2658 1.48438L0.272043 18.5469C-0.497176 19.8547 0.460902 21.5 2.00267 21.5H21.9995C23.5352 21.5 24.4961 19.8594 23.732 18.5469ZM10.8742 6.875C10.8742 6.25391 11.3781 5.75 11.9992 5.75C12.6203 5.75 13.1242 6.25625 13.1242 6.875V12.875C13.1242 13.4961 12.6203 14 12.0414 14C11.4625 14 10.8742 13.4984 10.8742 12.875V6.875ZM11.9992 18.5C11.1855 18.5 10.5255 17.84 10.5255 17.0262C10.5255 16.2125 11.185 15.5525 11.9992 15.5525C12.8134 15.5525 13.473 16.2125 13.473 17.0262C13.4711 17.8391 12.8149 18.5 11.9992 18.5Z"
                                    fill="#F07B01"
                                />
                            </svg>
                        </div>
                    )}
                </div>
            </Link>
        </li>
    );
};
