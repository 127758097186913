import axios from 'axios';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { useEffect, useState } from 'react';
import BASE_URL from '../../services/apiConfig';
import authHeader from '../../services/auth-header';
import decodeToken from '../../services/decode';
import { closeModal, ModalButton, openModal } from '../modals/modal';
import modalStyle from './modal.module.scss';
gsap.registerPlugin(CustomEase);

export interface Location {
    id: number;
    name: string;
}
export interface ModalProps {
    callBack: () => void;
}

export const ModalAddSensor = ({ callBack }: ModalProps) => {
    const [scannedData, setScannedData] = useState<string | null>('');
    const [qrCodeScannerActive, setQrCodeScannerActive] = useState(false);
    const [notificationsEnabled, setNotificationsEnabled] = useState(true);
    const [locations, setLocations] = useState<Location[]>([]);
    const [locationId, setLocationId] = useState();
    const [cellName, setName] = useState('');
    const [temp, setTemp] = useState('');
    const [tempMax, setTempMax] = useState('8');
    const [tempMin, setTempMin] = useState('0');
    const token = localStorage.getItem('user')!;
    const tokenData = decodeToken();
    const buttonActionClose = () => {
        closeModal(callBack);
    };
    const buttonActionSubmit = async () => {
        alert(scannedData);
        if (scannedData == '') {
            alert('Nog geen koeling gescanned');
        } else {
            const updatedData = {
                name: cellName,
                location: locationId,
                defaultTemperature: temp,
                deviationMax: tempMax,
                deviationMin: tempMin,
                notificationActive: notificationsEnabled,
                scannedData: scannedData,
            };
            try {
                const response = await axios.post(`${BASE_URL}/cell`, updatedData, {
                    headers: authHeader(),
                });
                closeModal(callBack);
            } catch (error) {}
        }
    };
    const buttonActionDelete = () => {
        closeModal(callBack);
    };
    const handleLocationChange = (e: any) => {
        setLocationId(e);
    };
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setName(value);
    };
    const handleTempChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setTemp(value);
    };
    const handleTempMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setTempMax(value);
    };
    const handleTempMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setTempMin(value);
    };
    const handleNotificationsEnabledChange = () => {
        var n = notificationsEnabled;
        if (n) {
            n = false;
        } else {
            n = true;
        }
        setNotificationsEnabled(n);
    };

    useEffect(() => {
        let scanner: Html5QrcodeScanner;

        const startScanner = async () => {
            setQrCodeScannerActive(true);
            try {
                scanner = new Html5QrcodeScanner(
                    'reader',
                    {
                        fps: 24,
                        qrbox: { width: 250, height: 300 },
                    },
                    /* verbose= */ false,
                );

                scanner.render(
                    (decodedText: string, decodedResult: any) => {
                        setScannedData(decodedText);
                        scanner.clear();
                    },
                    (error: any) => {
                        // console.warn('Code scan error:', error);
                    },
                );
            } catch (err) {
                //console.error('Failed to start scanner:', err);
            }
        };
        if (!qrCodeScannerActive) {
            startScanner();
        }

        return () => {
            if (scanner) {
                console.log('joaaa');
                scanner.clear();
            }
        };
    }, []);
    useEffect(() => {
        openModal();
    });

    useEffect(() => {
        const getLocations = async () => {
            const token = localStorage.getItem('user')!;
            const tokenData = decodeToken();
            try {
                const response = await axios.get(
                    `${BASE_URL}/organisation/` + tokenData.organisation_id + `/locations`,
                    {
                        headers: authHeader(),
                    },
                );
                //console.log(response.data.results);
                setLocations(response.data.results);
                handleLocationChange(response.data.results[0].id);
            } catch (error) {
                console.error('Error updating cell data:', error);
            }
        };

        getLocations();
    }, []);

    return (
        <>
            <div className={modalStyle['modal-overlay']}>
                <div
                    className={modalStyle['modal-background']}
                    id="modal-background"
                    onClick={() => closeModal(callBack)}
                ></div>

                <div className={modalStyle['modal-element']} id="modal-element">
                    <div className={modalStyle['modal-content']}>
                        <div className={modalStyle['modal-title']}>
                            <h3>Sensor Toevoegen</h3>
                            <h5>Scan de QR code</h5>
                        </div>
                        <div className={modalStyle['modal-qr-reader']}>
                            <div className={modalStyle['b1']}></div>
                            <div className={modalStyle['b2']}></div>
                            <div className={modalStyle['b3']}></div>
                            <div className={modalStyle['b4']}></div>
                            <div id="reader" className={modalStyle['modal-camera']}></div>
                            {scannedData && <p>Sensor: {scannedData}</p>}
                        </div>

                        <div className={modalStyle['modal-form']}>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="">Locatie:</label>
                                <select
                                    name="location"
                                    onChange={(e) => handleLocationChange(e.target.value)}
                                    defaultValue={locationId}
                                >
                                    {locations.map((location: Location) => (
                                        <option key={location.id} value={location.id}>
                                            {location.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="">Naam:</label>
                                <input type="text" value={cellName} onChange={handleNameChange} />
                            </div>
                            {/*<div className={modalStyle['form-group']}>
                                <label htmlFor="">Standaard temperatuur:</label>
                                <input type="text" value={temp} onChange={handleTempChange} />
                                    </div>*/}
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="">Maximale temperatuur:</label>
                                <input type="text" value={tempMax} onChange={handleTempMaxChange} />
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="">Minimale temperatuur:</label>
                                <input type="text" value={tempMin} onChange={handleTempMinChange} />
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="repeats" className={modalStyle['inline']}>
                                    Notificaties
                                </label>
                                <div className={modalStyle['checkbox-container']}>
                                    <div
                                        className={`${modalStyle['checkbox']} ${
                                            notificationsEnabled === true
                                                ? modalStyle['-checked']
                                                : ''
                                        }`}
                                        onClick={handleNotificationsEnabledChange}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={modalStyle['modal-buttons']}>
                        <ModalButton
                            callBack={buttonActionClose}
                            style="light"
                            icon="close"
                            text="Annuleren"
                        />
                        <ModalButton
                            callBack={buttonActionSubmit}
                            style=""
                            icon="plus"
                            text="Opslaan"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
