import classNames from 'classnames';
import styles from './button-small.module.scss';

export interface ButtonSmallProps {
    className?: string;
    children?: JSX.Element | string;
    onClick?: () => void;
}

export const ButtonSmall = ({ className, children, onClick }: ButtonSmallProps) => {
    return (
        <div className={classNames(styles.root, className)}>
            <button className={styles.small} onClick={onClick}>
                {children}
            </button>
        </div>
    );
};
