import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CardCell } from '../../components/cards/card-cell';
import { HeaderTitle } from '../../components/header-title/header-title';
import { ModalEditCell } from '../../components/modals/edit-cell';
import BASE_URL from '../../services/apiConfig';
import authHeader from '../../services/auth-header';
import styles from './cells.module.scss';

export interface LocationProps {}

export const Cells = ({}: LocationProps) => {
    const { id } = useParams<{ id: string }>();
    const [cellsData, setCellsData] = useState<any>([]);
    const [cellDataLoaded, setCellDataLoaded] = useState(false);
    const [cellsDataBase, setCellsDataBase] = useState<any>([]);
    const [cellsDescription, setCellsDescription] = useState('');
    const [currentCell, setCurrentCell] = useState<any>(null);
    const [location, setLocation] = useState<Number>(0);
    const [locations, setLocations] = useState<Object[]>([]);
    const [showModalEditCell, setShowModalEditCell] = useState(false);
    const token = localStorage.getItem('user')!;
    var tokenData = JSON.parse(atob(token));

    const fetchLocationsData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/organisation/`, {
                headers: authHeader(),
            });
            console.log(response);
            var locations = response.data.results.locations;
            console.log(locations);
            setLocations(locations);
        } catch (error) {
            console.log(error);
        }
    };
    const fetchCellsData = async () => {
        try {
            const response = await axios.get(
                `${BASE_URL}/organisation/` + tokenData.organisation_id + `/cells`,
                {
                    headers: authHeader(),
                },
            );
            setCellsDataBase(response.data.results);
            setCellsData(response.data.results);
            setCellDataLoaded(true);
            var cellLength = response.data.results.length;
            if (cellLength > 0) {
                setCellsDescription(`Aantal koelingen: ${cellLength}`);
            } else {
                setCellsDescription(`Nog geen koelingen`);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchLocationsData();
        fetchCellsData();
    }, [id]);

    useEffect(() => {
        var cellsData2 = cellsDataBase;
        if (location) {
            var filteredData = cellsData2.filter((cell: any) => cell.location_id === location);
        } else {
            var filteredData = cellsData2;
        }

        setCellsData(filteredData);
    }, [location]);
    const openEditCellModal = (cell: any) => {
        setCurrentCell(cell);
        setShowModalEditCell(true);
    };
    const callbackEditCell = async () => {
        fetchCellsData();
        setShowModalEditCell(false);
    };

    return (
        <>
            <HeaderTitle title="Koelingen" description={cellsDescription} />

            <>
                <section className={styles['cell-list-container']}>
                    <div className="grid-container">
                        {locations.length != 0 && (
                            <div className={styles['location-tabs']}>
                                <ul className="">
                                    <li
                                        className={location === 0 ? styles['active'] : ''}
                                        onClick={(e) => setLocation(0)}
                                    >
                                        Alle Locaties
                                    </li>
                                    {locations.map((location2: any) => (
                                        <li
                                            className={
                                                location === location2.id ? styles['active'] : ''
                                            }
                                            onClick={(e) => setLocation(location2.id)}
                                        >
                                            {location2.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <ul className={styles['cell-list']} id="cell-list">
                            {cellsData.map((cell: any) => (
                                <CardCell cell={cell} callBack={() => openEditCellModal(cell)} />
                            ))}
                        </ul>
                    </div>
                </section>
                {showModalEditCell && (
                    <ModalEditCell cell={currentCell} callBack={() => callbackEditCell()} />
                )}
            </>
        </>
    );
};
