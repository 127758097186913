import styles from './header-title.module.scss';

export type Location = {
    id: number;
    organisation_id: number;
    name: string;
    address_city: string;
    address_postalcode: string;
    address_street: string;
};

export interface HeaderTitleProps {
    title?: string;
    description?: string;
    actions?: any;
    className?: string;
}

export const HeaderTitle = (props: HeaderTitleProps) => {
    return (
        <section className={styles['padding-big']}>
            <div className="grid-container">
                <div className={styles['header-title']}>
                    <h1>{props.title}</h1>
                    <p>{props.description}</p>
                    {props.actions && props.actions.map((d: any) => <></>)}
                </div>
            </div>
        </section>
    );
};
