import axios from 'axios';
import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import logo from '../../assets/logo.svg';
import { NeedHelp } from '../need-help/need-help';
import styles from './register-user.module.scss';

import BASE_URL from '../../services/apiConfig';
import { register } from '../../services/auth-service';

export interface RegisterUserProps {
    className?: string;
}

export const RegisterUser = ({ className }: RegisterUserProps) => {
    const { hash } = useParams();
    const [successful, setSuccessful] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [showForm, setShowForm] = useState<boolean>(false);
    const [showErrorForm, setShowErrorForm] = useState<boolean>(false);
    let navigate: NavigateFunction = useNavigate();

    useEffect(() => {
        axios
            .get(`${BASE_URL}/user/activate/checkhash?hash=${hash}`)
            .then((response) => {
                if (response.data.results && response.data.results.length > 0) {
                    setShowForm(true);
                } else {
                    setShowForm(false);
                    setShowErrorForm(true);
                    setMessage('Invalid hash.');
                }
            })
            .catch((error) => {
                console.error(error);
                setShowForm(false);
                setMessage('Error checking the hash.');
            });
    }, [hash]);

    const initialValues = {
        hash: hash,
        username: '',
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
    };

    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .min(3, 'De gebruikersnaam moet uit minstens 3 tekens bestaan')
            .max(20, 'De gebruikersnaam mag maximaal 20 tekens bevatten')
            .required('Gebruikersnaam is vereist'),
        firstName: Yup.string()
            .min(1, 'Voornaam is verplicht')
            .max(30, 'De voornaam mag maximaal 30 tekens bevatten')
            .required('Voornaam is verplicht'),
        lastName: Yup.string()
            .min(1, 'Achternaam is verplicht')
            .max(30, 'Achternaam mag maximaal 30 tekens bevatten')
            .required('Achternaam is verplicht'),
        password: Yup.string()
            .min(6, 'Wachtwoord moet minstens 6 tekens bevatten')
            .max(40, 'Wachtwoord mag maximaal 40 tekens bevatten')
            .required('Wachtwoord is vereist'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), ''], 'Wachtwoorden moeten overeenkomen')
            .required('Wachtwoordbevestiging is vereist'),
    });

    const handleRegister = (
        values: any,
        { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
    ) => {
        const { hash, username, firstName, lastName, password } = values;

        register(hash, username, firstName, lastName, password).then(
            (response) => {
                // setMessage(response.data.message);
                // setSuccessful(true);
                // setSubmitting(false);
                navigate('/locations');
                window.location.reload();
            },
            (error) => {
                const resMessage =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();

                setMessage(resMessage);
                setSuccessful(false);
                setSubmitting(false);
            },
        );
    };

    return (
        <section className={classNames(styles.root, className)}>
            <div className={styles.container}>
                <div className={styles.logo}>
                    <img
                        src={logo}
                        className={classNames(styles['App-logo'], styles['img-logo'])}
                        alt="logo"
                    />
                </div>
                <div className={styles.login}>
                    <h4>Account activeren</h4>
                    {showForm && !showErrorForm ? (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleRegister}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="username"></label>
                                            <Field
                                                name="username"
                                                type="text"
                                                className="form-control"
                                                placeholder="Gebruikersnaam"
                                            />
                                            <ErrorMessage
                                                name="username"
                                                component="div"
                                                className="alert alert-danger"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="firstName"></label>
                                            <Field
                                                name="firstName"
                                                type="text"
                                                className="form-control"
                                                placeholder="Voornaam"
                                            />
                                            <ErrorMessage
                                                name="firstName"
                                                component="div"
                                                className="alert alert-danger"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="lastName"></label>
                                            <Field
                                                name="lastName"
                                                type="text"
                                                className="form-control"
                                                placeholder="Achternaam"
                                            />
                                            <ErrorMessage
                                                name="lastName"
                                                component="div"
                                                className="alert alert-danger"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password"></label>
                                            <Field
                                                name="password"
                                                type="password"
                                                className="form-control"
                                                placeholder="Wachtwoord"
                                            />
                                            <ErrorMessage
                                                name="password"
                                                component="div"
                                                className="alert alert-danger"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="confirmPassword"></label>
                                            <Field
                                                name="confirmPassword"
                                                type="password"
                                                className="form-control"
                                                placeholder="Confirm Password"
                                            />
                                            <ErrorMessage
                                                name="confirmPassword"
                                                component="div"
                                                className="alert alert-danger"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <button
                                                type="submit"
                                                className="btn login"
                                                disabled={isSubmitting}
                                            >
                                                <span>
                                                    {isSubmitting ? 'Submitting...' : 'Activeren'}
                                                </span>
                                            </button>
                                        </div>
                                        {message && (
                                            <div className="form-group">
                                                <div
                                                    className={
                                                        successful
                                                            ? 'alert alert-success'
                                                            : 'alert alert-danger'
                                                    }
                                                    role="alert"
                                                >
                                                    {message}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                </Form>
                            )}
                        </Formik>
                    ) : showErrorForm ? (
                        <div className="form-group">
                            <div className={classNames(styles['alert-danger'])}>
                                <p>Ongeldige activatie. Neem contact op met support</p>
                                <NeedHelp />
                            </div>
                        </div>
                    ) : null}
                </div>
                {/* <div className={styles.register}>
                        <p className={styles.small}>wachtwoord vergeten?</p>
                        <ButtonSmall>account aanmaken</ButtonSmall>
                    </div> */}
            </div>
        </section>
    );
};
