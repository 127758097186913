import axios from 'axios';
import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import BASE_URL from '../../services/apiConfig';
import authHeader from '../../services/auth-header';
import decodeToken from '../../services/decode';
import styles from './personal-settings.module.scss';
export interface PersonalSettingsProps {
    className?: string;
    organizationName?: string;
}

export const PersonalSettings = ({ className, organizationName }: PersonalSettingsProps) => {
    const token = localStorage.getItem('user')!;
    const tokenData = decodeToken();

    const [successful, setSuccessful] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [showForm, setShowForm] = useState<boolean>(true);
    const [showResponse, setShowResponse] = useState<boolean>(false);
    const [organisationPhone, setOrganisationPhone] = useState<string>('');
    const [initialValues, setInitialValues] = useState<Object>({ phone: '' });

    //console.log(tokenData);
    useEffect(() => {
        async function fetchData2() {
            try {
                const token = localStorage.getItem('user')!;
                const tokenData = decodeToken();

                const response = await axios.get(`${BASE_URL}/organisation`, {
                    headers: authHeader(),
                });
                const { results } = response.data;

                const phone = results.phone;

                if (phone) {
                    return results;
                }
            } catch (error) {
                console.error('Error fetching organization data:', error);
            }
        }
        fetchData2().then((res) => setInitialValues(res));
    }, []);

    const validationSchema = Yup.object().shape({
        phone: Yup.string()
            .min(6, 'Dit telefoonnummer is te kort')
            .max(100, 'Dit telefoonnummer is te lang')
            .required('Dit veld is vereist'),
    });

    const handleRegister = async (
        values: any,
        { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
    ) => {
        const { phone } = values;

        try {
            // Use the correct URL here
            const data = {
                phone: phone,
            };
            const response = await axios.post(`${BASE_URL}/organisation`, data, {
                headers: authHeader(),
            });
            setMessage('asdf');
            setSuccessful(false);
            setSubmitting(false);
            //console.log('Registration successful:', response);
            // Close the modal here or perform any other actions
        } catch (error) {
            alert('error');
            setSuccessful(false);
            setSubmitting(false);
        }
    };

    return (
        <div className={classNames(styles.root, className)}>
            <div className={styles['settings-container']}>
                <div className={styles['settings-group']}>
                    <h4 className={styles['h4-settings']}>Organisatie</h4>
                    <div className={styles.divider} />
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                        onSubmit={handleRegister}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <>
                                    <div className={`${styles['settings-group']}`}>
                                        <div className={styles['settings-input']}>
                                            <p className={styles.label}>Naam:</p>
                                            <p className={styles.small}>{organizationName}</p>
                                        </div>
                                        <div className={styles.divider} />

                                        <div className={styles['settings-input']}>
                                            <p className={styles.label}>Telefoonnummer:</p>
                                            <Field
                                                name="phone"
                                                type="tel"
                                                className={`${styles['form-input']}`}
                                            />
                                            <ErrorMessage
                                                name="phone"
                                                component="div"
                                                className="alert alert-danger"
                                            />
                                        </div>
                                        <div className={styles['settings-input']}>
                                            <button
                                                type="submit"
                                                className={styles['button']}
                                                disabled={isSubmitting}
                                            >
                                                <span>
                                                    {isSubmitting ? 'Versturen...' : 'Opslaan'}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className={styles['settings-group']}>
                    <h4 className={styles['h4-settings']}>Account gegvens</h4>
                    <div className={styles.divider} />
                    <div className={styles['settings-input']}>
                        <p className={styles.label}>Gebruikersnaam:</p>
                        <p className={styles.small}>{tokenData.username}</p>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles['settings-input']}>
                        <p className={styles.label}>Volledige Naam:</p>
                        <p className={styles.small}>
                            {tokenData.firstname} {tokenData.lastname}
                        </p>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles['settings-input']}>
                        <p className={styles.label}>Email:</p>
                        <p className={styles.small}>{tokenData.email}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
